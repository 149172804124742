import { message } from "antd";
import { useMutation, useQuery } from "react-query";
import { createApiClientKey, createApiClientKeyScope, getApiClientKeyDetail, getApiClientKeySecret } from "../services";
import { queryClientApp } from "../../../App";
import { CreateApiClientKeyParams, CreateApiClientKeyScopeParams } from "../Info/types";
import { GetAuthServiceApiErrorMessages } from "../../../services/error";

let createApiClientKeyErrorMessage: string
let createApiClientKeyScopeErrorMessage: string

export function useKeySecret(id_api_client_key: string) {
  return useQuery({
    queryKey: ['key-secret', id_api_client_key],
    queryFn: () => getApiClientKeySecret(id_api_client_key),
    staleTime: 2 * 60 * 1000,
    retry: false,
    enabled: !!id_api_client_key,
    onError: (err: any) => {
      message.error(err?.message || 'Erro ao buscar segredo da chave do Clientes API');
    },
  });
}

export function useKeyDetails(id_api_client_key: string) {
  return useQuery({
    queryKey: ['key-details', id_api_client_key],
    queryFn: () => getApiClientKeyDetail(id_api_client_key),
    staleTime: 2 * 60 * 1000,
    retry: false,
    enabled: !!id_api_client_key,
    onError: (err: any) => {
      message.error(err?.message || 'Erro ao buscar detalhes da chave do Clientes API');
    },
  });
}

export type useCreateApiClientKeyAndKeyScopeProp = {
  onSuccess: () => void;
}

export function useCreateApiClientKey({onSuccess}:useCreateApiClientKeyAndKeyScopeProp) {
  return useMutation({
    mutationFn: (data: CreateApiClientKeyParams) => createApiClientKey({scopes: data.scopes}, data.param_id_api_client),
    onSuccess: () => {
      queryClientApp.invalidateQueries(['client']);
      message.success('Chave de Cliente API criado com sucesso!')
      onSuccess();
    },
    onError: (error: any) => {
      createApiClientKeyErrorMessage = GetAuthServiceApiErrorMessages(error, 'Erro ao criar chave de Cliente API, tente novamente!' )
      message.error(createApiClientKeyErrorMessage);
    },
  });
}

export function useCreateApiClientKeyScope({onSuccess}:useCreateApiClientKeyAndKeyScopeProp) {
  return useMutation({
    mutationFn: (data: CreateApiClientKeyScopeParams) => createApiClientKeyScope({scope: data.scope}, data.param_id_api_client_key),
    onSuccess: () => {
      queryClientApp.invalidateQueries(['key-details']);
      message.success('Cliente API criado com sucesso!');
      onSuccess();
    },
    onError: (error: any) => {
    createApiClientKeyScopeErrorMessage = GetAuthServiceApiErrorMessages(error, 'Erro ao criar escopo para chave de Cliente API, tente novamente!')
    message.error(createApiClientKeyScopeErrorMessage);
  },
  });
}
