import { PagePagination } from "../../../types/Pagina";

export type GetCommissionsParams = {
  page: number;
  page_size: number;
}

export type GetCommissionsResponse = PagePagination<Commission>

export enum EnumCommissionStatus {
  WAITING = 'WAITING',
  DONE = 'DONE',
  REMOVED = 'REMOVED',
}

export type Commission = {
  checkout_id: string
  checkout_amount: number
  comission_amount: number
  created_at: string
  establishment_id: string
  id_comission: string
  status: EnumCommissionStatus
}
