import api from "../../../services/api"
import { CreateApiClientKey, CreateApiClientKeyScope, GetBackofficeApiClientDetailResponse, GetBackofficeApiClientKeyDetailResponse, GetBackofficeApiClientKeySecret } from "../Info/types";
import { CreateApiClient, ListApiClientResponse } from "../Pesquisar/types"

export async function createApiClient(data: CreateApiClient) {
  await api.post('/backoffice/api-client', data, {
    headers: {
      'id-establishment': data.id_establishment
    }
  })
}

export async function getApiClientDetail(id: string) {
  const response = await api.get<GetBackofficeApiClientDetailResponse>(`/backoffice/api-client/${id}`)

  return response.data
}

export async function getApiClientList(page: number) {
  const response = await api.get<ListApiClientResponse>(`/backoffice/api-client`, {
    params: {
      'page': page
    },
  })

  return response.data
}

export async function getApiClientKeyDetail(id_api_client_key: string) {
  const response = await api.get<GetBackofficeApiClientKeyDetailResponse>(`/backoffice/api-client/key/${id_api_client_key}`)

  return response.data
}

export async function getApiClientKeySecret(id_api_client_key: string) {
  const response = await api.get<GetBackofficeApiClientKeySecret>(`/backoffice/api-client/key/${id_api_client_key}/secret`)

  return response.data
}

export async function deleteApiClient(id_api_client: string) {
  const response = await api.delete(`/backoffice/api-client/${id_api_client}`)

  return response.data
}

export async function deleteApiClientKey(id_api_client_key: string) {
  const response = await api.delete(`/backoffice/api-client/key/${id_api_client_key}`)

  return response.data
}

export async function createApiClientKeyScope(data: CreateApiClientKeyScope, id_api_client_key: string) {
  await api.post(`/backoffice/api-client/key/${id_api_client_key}/scope`, data)
}

export async function deleteApiClientKeyScope(id_api_client_key_scope: string) {
  const response = await api.delete(`backoffice/api-client/key/${id_api_client_key_scope}/scope`)

  return response.data
}

export async function createApiClientKey(data: CreateApiClientKey, id_api_client: string) {
  await api.post(`/backoffice/api-client/${id_api_client}/key`, data)
}

export async function getUsers(id_user?: string) {
  const response = await api.get(`/usuario/${id_user}/tag`)
  return response.data
}