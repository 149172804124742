import React, { useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Modal,
  Tag,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import { ColumnsType } from 'antd/lib/table';
import 'moment/locale/pt-br';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import {
  setQueryParams, useQueryParams,
} from '../../../utils/UrlQuery';
import { useQuery } from "react-query";
import { getCommissions } from "../services";
import { EnumCommissionStatus, GetCommissionsParams } from "../services/types";
import { format } from "date-fns";
import UserName from "../../../components/User/Name";
import { Moment } from "moment";
import { MdFileDownload } from "react-icons/md";
import downloadFiles from "../../../utils/downloadFiles";

const { RangePicker } = DatePicker;
type RangeValue = [Moment | null, Moment | null] | null;

const CommissionsSearch: React.FC = () => {
  const default_filters = { page: 1, page_size: 25 };
  const [dates, setDates] = useState<RangeValue>(null);
  const query_values = useQueryParams();
  const [filters, setFilters] = useState<GetCommissionsParams>({...default_filters, ...query_values})

  const [form] = Form.useForm();

  const { isFetching: isLoading, data: commissions } = useQuery({
    queryKey: ['panel-commissions', filters],
    queryFn: () => getCommissions(filters),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    retry: false,
  });

  function handleSetFiltersValues(nextFilters: any) {
    let [start_date, end_date] = [...(nextFilters?.date || [])];

    if (!start_date && !end_date) {
      const dataField = form.getFieldValue('date') as any;
      [start_date, end_date] = dataField || [];
    }

    delete nextFilters.date;

    let final: GetCommissionsParams = {
      ...filters,
      ...nextFilters,
      start_date: start_date ? start_date.format('YYYY-MM-DD') : undefined,
      end_date: end_date ? end_date.format('YYYY-MM-DD') : undefined,
    };

    setQueryParams(final);
    setFilters(final);
  }

  function handleResetForm() {
    setQueryParams(default_filters);
    form.resetFields();
    setFilters(default_filters)
  }

  async function downloadFile(type = 'csv') {
    const { page, page_size, ...filtersForExport } = filters

    Modal.info({
      title: 'A exportação utilizará os filtros da pesquisa!',
      centered: true,
      onOk() {
        return downloadFiles(
          `/panel/commission/export`,
          `exportacao_comissao.${type}`,
          undefined,
          { ...filtersForExport, file_type: type } as any,
          { 'api-version': '1' },
        );
      },
    });
  }

  const columns: ColumnsType = [
    {
      title: 'Vendas',
      key: 'checkout_amount',
      dataIndex: 'checkout_amount',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Comissão',
      key: 'comission_amount',
      dataIndex: 'comission_amount',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Estabelecimento',
      key: 'establishment_id',
      dataIndex: 'establishment_id',
      align: 'center',
      render: (item: string) => <UserName id_user={item} />,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (value: EnumCommissionStatus) => {
        switch (value) {
          case EnumCommissionStatus.WAITING:
            return <Tag color="blue">Aguardando Aprovação</Tag>;
          case EnumCommissionStatus.DONE:
            return <Tag color="green">Transferência Realizada</Tag>;
          case EnumCommissionStatus.REMOVED:
            return <Tag color="red">Transferência Removida</Tag>;
    
          default:
            return <Tag>Status desconhecido</Tag>;
        }
      }
    },
    {
      title: 'Criado em',
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => {
        const createdAt = format(
          new Date(value),
          'dd/MM/yyyy HH:mm:ss',
        )

        return createdAt
      },
      width: 200,
    },
  ];

  const disabledRangeDateBiggerThen30Days = (current: Moment) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return !!tooEarly || !!tooLate;
  };

  return (
    <>
      <PageHeader title="Comissões" breadcrumb={['Comissões']} />

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          form={form}
          onFinish={data => handleSetFiltersValues(data)}
        >
          <Row gutter={16}>
            <Col md="12" xs="24">
              <Form.Item label="Data" name="date">
                <RangePicker
                  placeholder={['Data Inicio', 'Data Fim']}
                  disabledDate={disabledRangeDateBiggerThen30Days}
                  onCalendarChange={val => setDates(val)}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="amount_comission" label="Valor da comissão">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ amount_comission: val || undefined })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={commissions?.data ?? []}
        size="small"
        loading={isLoading}
        emptyText="Não há dados para mostrar!"
        pagination={{
          total: commissions?.total,
          current: commissions?.page,
          pageSize: commissions?.page_size,
          onChange: (page, page_size) => handleSetFiltersValues({ page, page_size }),
        }}
      >
        <Tooltip placement="topRight" title="Exportar para CSV">
          <Button
            onClick={() => downloadFile()}
            type="primary"
            shape="round"
            icon={<MdFileDownload />}
            disabled={!commissions?.total}
          />
        </Tooltip>
      </Table>

    </>
  );
};

export default CommissionsSearch;
