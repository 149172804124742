import { Table, Typography } from 'antd';
import { dinheiroMask } from 'masks-br';
import React, { useMemo } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { GetBackofficeCheckoutResponse } from '../types';

const { Text } = Typography;

type IInfoSumario = {
  checkout?: GetBackofficeCheckoutResponse;
  loading: boolean;
};

const CheckoutInfoSummary: React.FC<IInfoSumario> = ({
  checkout,
  loading,
}: IInfoSumario) => {
  const charges_val = useMemo(() => {
    return {
      total:
        checkout?.charges?.reduce(
          (prev, item) => prev + item.amount_total,
          0,
        ) || 0,
      children:
        checkout?.charges?.map((charge, index) => ({
          title: `Cobrança ${index + 1}`,
          value: charge.amount_total,
        })) || [],
    };
  }, [checkout]);
  const payments_val = useMemo(() => {
    return {
      total:
        (checkout?.payments?.reduce(
          (prev, item) => prev + item.total_amount,
          0,
        ) || 0) * -1,
      children:
        checkout?.payments?.map((payment, index) => ({
          title:
            payment.type === 'BILL'
              ? `Boleto ${index + 1}`
              : `Débito ${index + 1}`,
          value: payment.amount * -1,
        })) || [],
    };
  }, [checkout]);

  const commission_val = useMemo(() => {
    return {
      total:
        (checkout?.commissions?.reduce((prev, item) => prev + item.amount, 0) ||
          0) * -1,
      children:
        checkout?.commissions?.map((comissao, index) => ({
          title: `Comissão ${index + 1}`,
          value: comissao.amount * -1,
        })) || [],
    };
  }, [checkout?.commissions]);

  // const taxa_operacao_val = useMemo(() => {
  //   const final = checkout?.charges?.reduce(
  //     (prev, item) => {
  //       const mdr = item.amount_total * (item.mdr / 100);

  //       const valor_parcela =
  //         (item.valor_total - mdr) / item.condicao_pagamento;
  //       const antecipacao = Array.from(
  //         Array(item.condicao_pagamento).keys(),
  //       ).reduce(
  //         (_, i) =>
  //           valor_parcela * ((taxas_antecipacao_padrao * (i + 1)) / 100),
  //         0,
  //       );

  //       return {
  //         mdr: prev.mdr + mdr,
  //         antecipacao: prev.antecipacao + antecipacao,
  //       };
  //     },
  //     {
  //       mdr: 0,
  //       antecipacao: 0,
  //     },
  //   );

  //   const total = (final?.mdr + final?.antecipacao) * -1;

  //   return {
  //     total,
  //     children: [
  //       {
  //         title: `MDR`,
  //         value: final?.mdr * -1 || 0,
  //       },
  //       {
  //         title: `Antecipação`,
  //         value: final?.antecipacao * -1 || 0,
  //       },
  //     ],
  //   };
  // }, [carrinho.formas_pagamento]);

  const valueColor = (value: number) => {
    if (value >= 0) {
      return <Text type="success">+ {dinheiroMask(value)}</Text>;
    }

    return <Text type="danger">- {dinheiroMask(value * -1)}</Text>;
  };

  return (
    <>
      <CardCollapse title="Sumário" loading={loading}>
        <Table
          size="small"
          pagination={false}
          showHeader={false}
          columns={[
            {
              dataIndex: 'title',
              key: 'title',
              width: '50%',
            },
            {
              dataIndex: 'value',
              key: 'value',
              width: '50%',
              align: 'right',
              render: (val: number) => valueColor(val),
            },
          ]}
          dataSource={[
            {
              // @ts-ignore
              key: '1',
              title: 'Cobrança',
              value: charges_val.total,
              children: charges_val.children,
            },
            {
              // @ts-ignore
              key: '2',
              title: 'Pagamentos',
              value: payments_val.total,
              children: payments_val.children,
            },
            {
              // @ts-ignore
              key: '3',
              title: 'Comissão',
              value: commission_val.total,
              children: commission_val.children,
            },
            // {
            //   // @ts-ignore
            //   key: '4',
            //   title: 'Taxa de Operação',
            //   value: taxa_operacao_val.total,
            //   children: taxa_operacao_val.children,
            // },
          ]}
          summary={(items: readonly { title: string; value: number }[]) => {
            const total = items.reduce((prev, item) => prev + item.value, 0);

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  {valueColor(total)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </CardCollapse>
    </>
  );
};

export default CheckoutInfoSummary;
