import React from 'react';
import { Tag, Tooltip } from 'antd';
import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiFillCloseCircle,
  AiFillCreditCard,
} from 'react-icons/ai';
import { FaShieldAlt } from 'react-icons/fa';
import { RiFilePaperLine } from 'react-icons/ri';
import { GiPunch } from 'react-icons/gi';

import { StatusTipo } from '../../store/modules/utils/types';
import { InfoCircleOutlined } from "@ant-design/icons";

export enum EnumCheckoutStatus {
  INITIATED = 'INITIATED',
  CREATED = 'CREATED',
  '3DS_VALIDATION_REQUESTED' = '3DS_VALIDATION_REQUESTED',
  '3DS_APPROVED' = '3DS_APPROVED',
  '3DS_REPROVED' = '3DS_REPROVED',
  '3DS_ERROR' = '3DS_ERROR',
  RISK_ANALYSIS_STARTED = 'RISK_ANALYSIS_STARTED',
  RISK_ANALYSIS_APPROVED = 'RISK_ANALYSIS_APPROVED',
  RISK_ANALYSIS_REPROVED = 'RISK_ANALYSIS_REPROVED',
  CHARGE_WAITING = 'CHARGE_WAITING',
  CHARGE_SUCCEEDED = 'CHARGE_SUCCEEDED',
  CHARGE_NOT_SUCCEEDED = 'CHARGE_NOT_SUCCEEDED',
  DEBTS_PAYMENT_STARTED = 'DEBTS_PAYMENT_STARTED',
  DEBTS_PAYMENT_DONE = 'DEBTS_PAYMENT_DONE',
  DEBTS_PAYMENT_ERROR = 'DEBTS_PAYMENT_ERROR',
  COMPLETED = 'COMPLETED',
  IN_DISPUTE = 'IN_DISPUTE',
  CHARGEBACK = 'CHARGEBACK',
  REVERSED = 'REVERSED',
  REMOVED = 'REMOVED',
}

export function findStatusCarrinho(carrinho: any) {
  if (carrinho?.removido_em) {
    return <Tag color="red">Removido</Tag>;
  }

  if (carrinho?.concluido_em) {
    return <Tag color="green">Concluido</Tag>;
  }

  return <Tag>Aguardando confirmação</Tag>;
}

export function findStatusColor(
  name: string,
  tipo: StatusTipo,
  tooltip?: string,
) {
  let color = '';

  switch (tipo?.toLowerCase()) {
    case 'danger':
    case 'error':
      color = 'red';
      break;
    case 'info':
      color = 'blue';
      break;
    case 'success':
      color = 'green';
      break;

    case 'warning':
      color = 'gold';
      break;

    default:
      color = 'default';
      break;
  }

  const tag = <Tag icon={<InfoCircleOutlined />} color={color}>{name}</Tag>;

  if (tooltip) {
    return (
      <Tooltip placement="top" title={tooltip}>
        {tag}
      </Tooltip>
    );
  }

  return tag;
}

export function findStatusCheckoutIcon(
  tipo: string,
  id_status: EnumCheckoutStatus,
) {
  if (id_status === EnumCheckoutStatus.RISK_ANALYSIS_STARTED) {
    return <FaShieldAlt />;
  }
  if (id_status === EnumCheckoutStatus.CHARGE_WAITING) {
    return <AiFillCreditCard />;
  }
  // if (id_status_carrinho === EnumCartStatus.) {
  //   return <AiOutlineCreditCard />;
  // }
  if (id_status === EnumCheckoutStatus.DEBTS_PAYMENT_STARTED) {
    return <RiFilePaperLine />;
  }
  // if (id_status_carrinho === EnumCartStatus.) {
  //   return <MdFileUpload />;
  // }
  if (id_status === EnumCheckoutStatus.IN_DISPUTE) {
    return <GiPunch />;
  }

  switch (tipo?.toLocaleLowerCase()) {
    case 'danger':
    case 'error':
      return <AiFillCloseCircle />;
    case 'info':
      return <AiFillInfoCircle />;
    case 'success':
      return <AiFillCheckCircle />;

    default:
      return null;
  }
}

export function getColor(info: string) {
  switch (info) {
    case 'danger':
    case 'error':
      return 'red';
    case 'info':
      return 'blue';
    case 'success':
      return 'green';

    default:
      return 'gray';
  }
}

export function findTransacaoStatus(transacao: any) {
  const retorno = {
    status: <Tag color="blue">Aguardando Aprovação</Tag>,
    id_usuario_status: undefined,
    nome_usuario_status: undefined,
  };

  if (transacao.removido_por) {
    retorno.status = <Tag color="red">Transferência Removida</Tag>;
    retorno.id_usuario_status = transacao.removido_por;
    retorno.nome_usuario_status = transacao.nome_usuario_removido;
  }

  if (transacao.token_transacao) {
    retorno.status = <Tag color="green">Transferência Realizada</Tag>;
    retorno.id_usuario_status = transacao.id_usuario_aprovacao;
    retorno.nome_usuario_status = transacao.nome_usuario_aprovacao;
  }

  return retorno;
}

export function findFormaPagamento(
  forma_pagamento: any,
  id_status_carrinho: EnumCheckoutStatus,
) {
  if (id_status_carrinho !== EnumCheckoutStatus.COMPLETED) {
    return <Tag color="red">Pagamento não concluido</Tag>;
  }

  if (forma_pagamento.pagamento_confirmado_em) {
    return <Tag color="green">Pagamento Confirmado</Tag>;
  }

  if (forma_pagamento.pagamento_estornado_em) {
    return <Tag color="gold">Pagamento Cancelado/Estornado</Tag>;
  }

  return <Tag color="blue">Pagamento Realizado</Tag>;
}
