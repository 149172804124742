export interface GetBackofficeApiClientDetailKeysResponse {
  id_api_client_key: string;
  id_api_client: string;
  created_at: string;
  created_by: string;
}

export type ApiClient = {
  id_api_client: string;
  establishment_id: string;
  name: string;
  description: string;
  created_at: string;
  created_by: string;
  removed_at?: string;
  removed_by?: string;
}

export type GetBackofficeApiClientDetailResponse = {
  id_api_client: string;
  establishment_id: string;
  name: string;
  description: string;
  created_at: string;
  created_by: string;
  removed_at?: string;
  removed_by?: string;
  keys: GetBackofficeApiClientDetailKeysResponse[];
}

export interface GetApiClientKeyScopesResponse {
  id_api_client_key_scope: string;
  scope: string;
  created_at: string;
  created_by: string;
}

export type GetBackofficeApiClientKeyDetailResponse = {
  id_api_client_key: string;
  id_api_client: string;
  created_at: string;
  created_by: string;
  removed_at?: string;
  removed_by?: string;
  scopes: GetApiClientKeyScopesResponse[];
  modal?: boolean;
  scopesList?: string[]
}

export type GetBackofficeApiClientKeySecretResponse = {
  secret_key: string;
  modal?: boolean;
}

export type GetBackofficeApiClientKeySecret = {
  secret_key: string
}

export type CreateApiClientKey = {
  scopes: string[]
}

export type CreateApiClientKeyParams = {
  param_id_api_client: string
  scopes: string[]
}

export type CreateApiClientKeyScope = {
  scope: string
}

export type CreateApiClientKeyScopeParams = {
  param_id_api_client_key: string
  scope: string
}

export enum EnumScopeType {
PARTNER_LIST = 'partner.list',
PARTNER_SEARCH = 'partner.search',
BILL_SEARCH = 'bill.search',
BILL_CREATE_MANUALLY = 'bill.create.manually',
CLIENT_CREATE = 'client.create',
CLIENT_UPDATE = 'client.update',
CLIENT_READ = 'client.read',
CHARGE_OPTION_CREATE = 'charge.option.create',
CHECKOUT_CREATE = 'checkout.create',
CHECKOUT_READ = 'checkout.read',
PAYMENT_LINK_CREATE = 'payment-link.create',
PAYMENT_LINK_SEARCH = 'payment-link.search',
PAYMENT_LINK_UPDATE = 'payment-link.update',
PAYMENT_LINK_READ = 'payment-link.read',
ORDER_CREATE = 'order.create',
ORDER_READ = 'order.read',
ORDER_CAPTURE = 'order.capture',
ORDER_CANCEL = 'order.cancel',
ORDER_REFUND = 'order.refund',
ORDER_3DS = 'order.3ds',
WEBHOOK_CREATE = 'webhook.create',
WEBHOOK_SEARCH = 'webhook.search',
WEBHOOK_CANCEL = 'webhook.cancel',
}

export const scopeTypeLabels = {
  'partner.list': 'partner.list',
  'partner.search': 'partner.search',
  'bill.search': 'bill.search',
  'bill.create.manually': 'bill.create.manually',
  'client.create': 'client.create',
  'client.update': 'client.update',
  'client.read': 'client.read',
  'charge.option.create': 'charge.option.create',
  'checkout.create': 'checkout.create',
  'checkout.read': 'checkout.read',
  'payment-link.create': 'payment-link.create',
  'payment-link.search': 'payment-link.search',
  'payment-link.update': 'payment-link.update',
  'payment-link.read': 'payment-link.read',
  'order.create': 'order.create',
  'order.read': 'order.read',
  'order.capture': 'order.capture',
  'order.cancel': 'order.cancel',
  'order.refund': 'order.refund',
  'order.3ds': 'order.3ds',
  'webhook.create': 'webhook.create',
  'webhook.search':  'webhook.search',
  'webhook.cancel': 'webhook.cancel',
}
